import {useEffect, useState} from 'react';
import Arg from '@livongo/arg';
import Loader from '@teladoc/pulse/ui/Loader';
import useTransLoader from 'i18n/use-trans-loader';
import {authenticate} from 'api/protobuf/enrollment/services';
import {
    setIsAuthenticated,
    setGrpcAccessToken,
    setGrpcAccessTokenTimeStamp,
    setSsoContext,
    setUserUUID,
} from 'utilities/utils';
import {handleSSOFailed, handleSSOSuccess} from './SsoUtils';

const SSO = () => {
    const {t} = useTransLoader('app');
    const arg = Arg.all();
    const mixpanelId = 'mid';
    const isOneApp = arg.oneapp === true;
    const [showNewContent, setShowNewContent] = useState(false);

    setTimeout(() => {
        setShowNewContent(true);
    }, 10000);

    useEffect(() => {
        if (arg.registrationAccessCode) {
            setUserUUID(arg[mixpanelId]);

            if (!arg.oneapp) {
                //  legacy SSO flow
                authenticate({
                    accessCode: arg.registrationAccessCode,
                    isAuthAndNav: false,
                })
                    .then(response => {
                        const accessToken =
                            response.authenticated?.accessToken?.token;

                        const ssoContextList =
                            response.registrationContext?.sso?.cqmTriggerInfo;

                        if (ssoContextList) {
                            setSsoContext({
                                isImpersonation:
                                    ssoContextList.location?.value?.includes(
                                        'Impersonation'
                                    ),
                                agentNumber:
                                    ssoContextList.agent?.value?.match(/\d+/) ??
                                    '0',
                            });
                        }

                        if (accessToken) {
                            setGrpcAccessToken(accessToken);
                            setGrpcAccessTokenTimeStamp(new Date().getTime());
                            setIsAuthenticated(true);
                            handleSSOSuccess();
                        } else {
                            handleSSOFailed();
                        }
                    })
                    .catch(exception => {
                        handleSSOFailed();
                    });
            }
        } else {
            handleSSOFailed();
        }
        // eslint-disable-next-line
    }, []);

    return isOneApp ? (
        <div className="loader-content">
            <Loader className="Loader-module-root" />
            {isOneApp &&
                (showNewContent ? (
                    <p>{t('loader.oneappLoadingContent1')}</p>
                ) : (
                    <p>{t('loader.oneappLoadingContent')}</p>
                ))}
        </div>
    ) : null;
};

export default SSO;
