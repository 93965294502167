import {Trans} from 'react-i18next';
import PropTypes from 'prop-types';
import Config from 'config';
import css from './NCQAInfo.scss';

const {
    memberSupportPhoneNumber,
    memberSupportFormattedPhoneNumber,
    FEPBLUEMemberSupportPhoneNumber,
    FEPBLUEMemberSupportFormattedPhoneNumber,
    memberHelpEmail,
} = Config;

const NCQAInfo = ({isFepBlue}) => {
    const displayFormattedPhoneNumber = isFepBlue
        ? FEPBLUEMemberSupportFormattedPhoneNumber
        : memberSupportFormattedPhoneNumber;

    const displayPhoneNumber = isFepBlue
        ? FEPBLUEMemberSupportPhoneNumber
        : memberSupportPhoneNumber;

    return (
        <div className={css.root}>
            <Trans
                i18nKey="app:NCQAInfo.context"
                values={{
                    displayFormattedPhoneNumber,
                }}
                components={[
                    /* eslint-disable react/jsx-key */
                    <a href={`tel:${displayPhoneNumber}`}>
                        {displayFormattedPhoneNumber}
                    </a>,
                    <a href={`mailto:${memberHelpEmail}`}>{memberHelpEmail}</a>,
                ]}
            />
        </div>
    );
};

NCQAInfo.propTypes = {
    isFepBlue: PropTypes.bool,
};

export default NCQAInfo;
