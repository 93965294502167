/**
 * This file configures the gRPC enrollment service
 * https://github.com/livongo/protos/tree/integration/src/livongo/protobuf/grpc/external/enrollment
 */
import {Date} from '@livongo/protobuf-grpc-external-enrollment/lib/livongo/protobuf/common/types/date_pb';
import {SemanticVersion} from '@livongo/protobuf-grpc-external-enrollment/lib/livongo/protobuf/common/types/semanticVersion_pb';
import {ProtocolVersion} from '@livongo/protobuf-grpc-external-enrollment/lib/livongo/protobuf/common/types/protocolVersion_pb';
import {RegistrationContext} from '@livongo/protobuf-grpc-external-enrollment/lib/livongo/protobuf/grpc/external/enrollment/common/registration_context_pb';
import {AccessToken} from '@livongo/protobuf-grpc-external-enrollment/lib/livongo/protobuf/grpc/external/enrollment/authentication/authentication_pb';
import {TypedValue} from '@livongo/protobuf-grpc-external-enrollment/lib/livongo/protobuf/grpc/external/enrollment/common/typed_value_pb';
import {QuestionId} from '@livongo/protobuf-grpc-external-enrollment/lib/livongo/protobuf/grpc/external/enrollment/common/question_ids_pb';
import {Answer} from '@livongo/protobuf-grpc-external-enrollment/lib/livongo/protobuf/grpc/external/enrollment/questions/answer_pb';
import MixpanelUtils from '@livongo/utils/mixpanel';
import {UserProgress} from '@livongo/protobuf-grpc-external-enrollment/lib/livongo/protobuf/grpc/external/enrollment/definition/user_progress_pb';
import {controlGroupTypes} from 'constants/type';
import {questionFieldTypes} from 'constants/questions';
import {
    getUserUUID,
    getCorrelationId,
    getIsRedirectFromTeams,
    getGrpcAccessToken as getAccessToken,
    getRegistrationContext,
    checkU13,
    getSsoContext,
} from 'utilities/utils';
import Config from 'config';
import {errorMessage} from 'utilities/errors-utils';

export {
    enrollmentPromiseClient,
    manageSolutionPromiseClient,
} from 'api/protobuf/services';

export const getGrpcProtocolVersion = () => {
    const protocolVersion = new ProtocolVersion();
    const semanticVersion = new SemanticVersion();
    const [major, minor, patch] = process.env.GRPC_VERSION.split('.');

    semanticVersion.setMajor(major);
    semanticVersion.setMinor(minor);
    // for patch number below, since the dev package might have trailing char and it will causing assertion error
    // so only get first few digit here
    semanticVersion.setPatch(patch.replace(/(^\d+)(.+$)/i, '$1'));

    protocolVersion.setProject(process.env.GRPC_ID);
    protocolVersion.setVersion(semanticVersion);

    return protocolVersion;
};

export const getGrpcRegistrationContext = user => {
    const context = getRegistrationContext() ?? {};
    const registrationContext = new RegistrationContext();

    if (context.client === Config.client.salesforce) {
        const salesforce = new RegistrationContext.Salesforce();

        salesforce.setAdminId(user);
        registrationContext.setSalesforce(salesforce);
    } else if (context.client === Config.client.galaxy) {
        const galaxy = new RegistrationContext.Galaxy();

        galaxy.getAdminId(user);
        registrationContext.setGalaxy(galaxy);
    } else if (context.client === Config.client.sso) {
        const sso = new RegistrationContext.SingleSignOn();

        registrationContext.setSso(sso);
    } else if (context.client === Config.client.oneapp) {
        const oneapp = new RegistrationContext.OneApp();

        registrationContext.setOneapp(oneapp);
    } else {
        const reg5 = new RegistrationContext.Reg5();

        registrationContext.setReg5(reg5);
    }

    return registrationContext;
};

export const getGrpcAccessToken = () => {
    const accessToken = new AccessToken();

    accessToken.setToken(getAccessToken());

    return accessToken;
};

export const getUpsellGrpcMetadata = authToken => {
    const metadataObj = {};

    metadataObj.Authorization = `Bearer ${authToken}`;

    return metadataObj;
};

export const getGrpcMetadata = withAuthToken => {
    const metadataObj = {'x-initial-request-uuid': getUserUUID()};
    const cid = getCorrelationId();
    const deviceID = getSsoContext()?.deviceID;
    const sessionID = getSsoContext()?.sessionID;

    if (withAuthToken) {
        metadataObj.Authorization = `Bearer ${process.env.ADMIN_AUTH_TOKEN}`;
    }

    if (cid) {
        metadataObj['x-correlation-id'] = cid;
    }

    if (getIsRedirectFromTeams()) {
        metadataObj['x-initial-app-type'] = 'MS Teams';
    }

    if (deviceID) {
        metadataObj['x-device-id'] = deviceID;
    }

    if (sessionID) {
        metadataObj['x-session-id'] = sessionID;
    }

    return metadataObj;
};

const {CONTROL, VARIATION_1, INVALID} = controlGroupTypes;

export const setControlGroupName = controlGroup => {
    switch (controlGroup) {
        case CONTROL:
            return UserProgress.ExperimentHistory.ControlGroup.CONTROL;
        case VARIATION_1:
            return UserProgress.ExperimentHistory.ControlGroup.VARIATION_1;
        case INVALID:
        default:
            return UserProgress.ExperimentHistory.ControlGroup.INVALID;
    }
};

const {
    TEXT_FIELD,
    SINGLE_SELECT_ENUM,
    MULTI_SELECT_ENUM,
    BOOLEAN_FIELD,
    INTEGER_FIELD,
    FLOAT_FIELD,
    DATE_FIELD,
    COMPOUND_FIELD,
    UNION_FIELD,
    PASSWORD_TEXT_FIELD,
} = questionFieldTypes;

/**
 * answerToProtobuf
 * Converts an answer from a javascript object to gRPC
 * @param {Object} ans answers to be sent to the server
 *  example:
 *       {
 *           questionId: {
 *               id: 21, // FIRST_NAME
 *               version: 1,
 *           },
 *           type: TypedField.FieldTypeCase.TEXT,
 *           value: 'Michael',
 *       },
 * @return {proto.com.livongo.protobuf.grpc.external.enrollment.questions.Answer} answer in gRPC format
 */
export const answerToProtobuf = ans => {
    if (!ans) {
        throw new Error(errorMessage('Missing answer', ans));
    }

    if (!ans.questionId) {
        throw new Error(errorMessage('Missing questionId for answer', ans));
    }

    if (!ans.questionId.id) {
        throw new Error(errorMessage('Missing questionId.id for answer', ans));
    }

    if (!ans.questionId.version) {
        throw new Error(
            errorMessage('Missing questionId.version for answer', ans)
        );
    }
    const questionId = new QuestionId();
    const typedValue = new TypedValue();
    const answer = new Answer();

    questionId.setId(ans.questionId.id);
    questionId.setVersion(ans.questionId.version);

    // switch to make the right call depending on the answer's data type
    // additional data parsing is done to make sure we send the data in the right format
    // TODO: Add error logging to mixpanel or logging
    switch (ans.type) {
        case TEXT_FIELD:
            if (ans.questionId.id === QuestionId.Values.REG_CODE) {
                typedValue.setText(ans.value.toString().toUpperCase().trim());
                MixpanelUtils.setSuperProps({
                    'Reg Code': ans.value.toString(),
                });
            } else {
                typedValue.setText(ans.value.toString().trim());
            }
            break;
        case BOOLEAN_FIELD:
            typedValue.setBoolean(ans.value === true || ans.value === 'true');
            break;
        case INTEGER_FIELD:
            typedValue.setInteger(parseInt(ans.value, 10));
            break;
        case FLOAT_FIELD:
            typedValue.setFloat(parseFloat(ans.value));
            break;
        case DATE_FIELD: {
            const {client} = getRegistrationContext() ?? {};
            const isSalesforce = client === Config.client.salesforce;

            if (
                ans.questionId.id === QuestionId.Values.BIRTH_DATE &&
                isSalesforce &&
                checkU13(ans.value)
            ) {
                throw new Error('salesforceU13Error');
            }

            if (ans.questionId.id === QuestionId.Values.A1C_LAST_CHECKED_DATE) {
                ans.value = `${ans.value.slice(0, 2)}/1/${ans.value.slice(
                    2,
                    6
                )}`;
            }

            const [month, day = '1', year] = ans.value.split('/');
            const date = new Date();

            date.setYear(Number(year));
            date.setMonth(Number(month));
            date.setDay(Number(day));

            typedValue.setDate(date);
            break;
        }
        case SINGLE_SELECT_ENUM:
            if (
                !ans.value &&
                (ans.questionId.id ===
                    QuestionId.Values.MOUNTSINAI_DIABETES_ALLIANCE ||
                    ans.questionId.id ===
                        QuestionId.Values.DIGNITY_HEALTH_TOU ||
                    ans.questionId.id === QuestionId.Values.UMASS_BG_DATA ||
                    ans.questionId.id === QuestionId.Values.LOCAL99_TOU)
            ) {
                typedValue.setSingleSelectEnumCode('NO');
            } else {
                typedValue.setSingleSelectEnumCode(ans.value);
            }
            break;
        case MULTI_SELECT_ENUM: {
            // in REGCON-762 design wants two options map to "ORAL_MEDICATIONS"
            // ORAL_MEDICATIONS_1 added on radioCard component so remove on here if ORAL_MEDICATIONS_1 checked
            if (ans.questionId.id === QuestionId.Values.DIABETES_MEDS) {
                if (ans.value.includes('ORAL_MEDICATIONS_1')) {
                    if (ans.value.includes('ORAL_MEDICATIONS')) {
                        ans.value = ans.value.filter(
                            item => item !== 'ORAL_MEDICATIONS_1'
                        );
                    } else {
                        ans.value[ans.value.indexOf('ORAL_MEDICATIONS_1')] =
                            'ORAL_MEDICATIONS';
                    }
                }
            }

            const repeatedEnum = new TypedValue.RepeatedEnum();

            repeatedEnum.setEnumCodeList(ans.value);
            typedValue.setMultiSelectEnum(repeatedEnum);
            break;
        }
        case COMPOUND_FIELD: {
            const compoundValue = new TypedValue.CompoundValue();

            typedValue.setCompound(compoundValue);
            ans.subValues.forEach(subAns => {
                compoundValue.addSubValues(answerToProtobuf(subAns));
            });
            break;
        }
        case UNION_FIELD: {
            const unionValue = new TypedValue.UnionValue();

            typedValue.setUnion(unionValue);

            unionValue.setSubValue(answerToProtobuf(ans.value));

            break;
        }
        case PASSWORD_TEXT_FIELD: {
            typedValue.setText(ans.value.toString());
            break;
        }
        default:
            throw new Error(
                errorMessage('Unsupported data type for answer', ans)
            );
    }

    answer.setQuestionId(questionId);
    answer.setValue(typedValue);

    return answer;
};
